.sd-page {
    padding: 0px !important;
}

.sd-row {
    margin-top: 0px !important;
}

.sd-table__cell {
    padding-bottom: 0px !important;
}

.sd-table__cell--header {
    padding-top: 0px !important;
    padding-bottom: calc(0.5 * var(--base-unit, 8px)) !important;
}

.sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top {
    padding-top: calc(.25*var(--sd-base-vertical-padding)) !important;
    padding-bottom: calc(.25*var(--sd-base-vertical-padding)) !important;
}

.sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top:after {
    bottom: calc(-.25*var(--sd-base-vertical-padding)) !important;
}

.sd-element:not(.sd-element--collapsed) {
    padding-top: 0px !important;
}

.sd-question__header--location-top {
    padding-top: calc(.25*var(--sd-base-vertical-padding)) !important;
    padding-bottom: calc(.25*var(--sd-base-vertical-padding)) !important;
}

.sd-question__header--location-top:after {
    content: " ";
    display: block;
    position: relative;
    bottom: calc(-.25*var(--sd-base-vertical-padding)) !important;
    ;
    height: 1px;
    background: var(--border-light, #eaeaea);
    width: calc(100% + 2*var(--sd-base-padding));
    left: calc(-1*var(--sd-base-padding));
}

.sd-row__question>.sd-question__content {
    padding-top: calc(2.5 * var(--base-unit, 8px)) !important;
}

.sd-question--table>.sd-question__content {
    padding-top: calc(1 * var(--base-unit, 8px)) !important;
}

div:only-child>.sd-element--with-frame:not(.sd-element--collapsed) {
    padding-bottom: calc(0.5 * var(--sd-base-padding)) !important;
}

.sd-table__cell--header,
.sd-table__cell--row-text,
.sd-matrix__cell:first-child {
    font-weight: normal !important;
}

.sd-item {
    padding: calc(1 * var(--base-unit, 8px)) 0 !important;
}

.sd-question__content a {
    color: inherit;
}

.sd-question__content dfn {
    text-decoration: underline;
    font-style: normal;
}

.sd-table__question-wrapper {
    padding: 2px;
}

.sd-panel__content>.sd-row .sd-question__content {
    padding-top: 0px !important;
}

/* remove question title border if there are several rows (not the first one) */
.sd-panel__content>.sd-row .sd-question__header--location-top:after {
    height: 0px !important;
}

/* move question header in panel */
.sd-panel__content>.sd-row .sd-question__header {
    top: 30px;
    z-index: 100;
    padding-top: 0px !important;
}

/*
    force question width with 'Oui'|'Non' as data-responsive-title
    TODO: find a better way
*/
.sd-matrix__cell[data-responsive-title='Oui'],
.sd-matrix__cell[data-responsive-title='Non'] {
    width: 50px;
}

.sd-body.sd-body--responsive {
    max-width: none !important;
    padding: 0 !important;
    box-sizing: content-box !important;
}

.sd-table__cell--header:not(.sd-table__cell--empty) {
    min-width: 0px !important;
}

.sd-table__cell--header:not(.sd-table__cell--empty) {
    min-width: 0px !important;
}

.sd-table.sd-table--columnsautowidth .sd-table__cell:not(.sd-table__cell--actions):not(.sd-table__cell--action):not(.sd-table__cell--empty.sd-table__cell--error) {
    width: auto !important;
}

.sd-panel:not(.sd-panel--as-page)>.sd-panel__content .sd-question--table>.sd-question__content .sd-table-wrapper .sd-table:not(.sd-table--no-header) {
    margin-top: 0% !important;
}

.sd-panel:not(.sd-panel--as-page)>.sd-panel__content>.sd-row:not(:first-of-type) .sd-question--table>.sd-question__content .sd-table-wrapper .sd-table:not(.sd-table--no-header) {
    margin-top: 3% !important;
}
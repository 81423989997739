.chapterContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
}

@media (max-width: 600px) {
    .chapterContainer {
        padding: 0px 0px 40px 0px;
    }
}

.formContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
}

.navigationContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0px 10px 10px 10px;
    justify-content: space-between;
}

.actionContainer {
    text-align: right;
}

.actionContainer span {
    padding: 0px;
    margin-right: -4px;
    margin-bottom: 4px;
}

.drawer {
    justify-content: center;
}
.introCard {
    margin: 0px 120px;
}

@media (max-width: 1200px) {
    .introCard {
        margin: 20px;
    }
}

.img {
    width: 30%;

}

.imgBandeau {
    width: 50%;
}

@media (max-width: 600px) {
    .introCard {
        margin: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }


}

.bolcImg {
    text-align: center;
}

.authors {
    text-align: right;
}

.authors span {
    font-style: italic;
}

.actions {
    justify-content: center;
}
.appTitle {
  flex: 1;
  text-align: center;
}

.appAnnee {

  font-weight: bold;
  text-align: center;
  height: 25PX;
  background-color: white;
}

.toolbar {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.modal {
  position: fixed;
  top: 15vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.fixedSelect {
  position: absolute;
  right: 46px;
  top: 50%;
  transform: translateY(-50%);
  color: #8b96bd;
}

.modalActions {
  display: flex;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
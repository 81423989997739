.containercss {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgBandeau {
    width: 100%;
    max-width: 60%;
    margin-bottom: 2%;
}

.img {
    width: 30%;
    height: auto;
    margin-bottom: 2%;
}

.grid_n1 {
    width: 100%;
    max-width: 80%;
}

.fild {
    text-align: left;
    margin-top: 16px;
    font-size: 1rem;
    padding-right: 10px;
}

.error_n1 {
    color: red;
    text-align: center;
}

.btn_ccdb {
    display: flex;
    justify-content: center;
}

.btn_n1,
.btn_stats {
    width: 30%;
    margin: 0 10px;
}

.btn_stats {
    background-color: deeppink;
}
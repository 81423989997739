.step, .step .disabledStepLabel{
    cursor: pointer;
}

.step .stepLabel svg{
    color: inherit;
}

.step .stepLabelContainer, .step .stepLabel .stepLabel{
    color: inherit;
}

.step .disabledStepLabel svg{
    color: rgba(0, 0, 0, 0.38);
}

.step .disabledStepLabel .stepLabel{
    color: rgba(0, 0, 0, 0.6);
}
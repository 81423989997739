.chapter{
    position: relative;
}

.cardIcon{
    position: absolute;
    right: 10px;
    top: 10px;
}

.image{
    height: 100%;
    max-height: 250px;
    width: auto;
    margin: 0px auto;
}

.title{
    text-align: center;
}